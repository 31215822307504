import { fetchAuthenticatedContent } from "@parallelpublicworks/entitysync";

export default async (auth, dispatch, userEntity, role = 'applicant') => {
  const roleIds = userEntity.relationships?.roles?.data;
  if (roleIds && roleIds.length > 0 && typeof window !== `undefined`) {
    const jsonapiEndpoint = 'user_role/user_role/';
    const method = "GET"; 
    for (let roleId of roleIds) {
      const resp = await fetchAuthenticatedContent(auth, dispatch, jsonapiEndpoint + roleId.id, method);
      if (resp && resp.data?.attributes?.drupal_internal__id === role) {
         return true;
      }
    }
  }
  return false;
}
