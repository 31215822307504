

  export default function parseAnswerAttached(response) {

    let { data, included = [] } = response;
    
    return data.map(applicant => {

      let id = applicant.relationships.field_biosketch?.data?.id;
      let userId = applicant.relationships.uid?.data?.id;
      
      let biosketch = included.filter(biosketch => biosketch.type == 'file--file').find(biosketch => biosketch.id === id) || {};
      let lors = included.filter(lor => lor.type == 'node--lor_request' && lor.relationships.uid?.data?.id === userId) || [];
      
      return {
        ...applicant,
        attributes : {
          ...applicant.attributes,
          field_biosketch : {
            ...applicant.relationships?.field_biosketch,
            ...biosketch
          },
          field_letters_of_recommendation: lors
        }
      }
    })
  }