// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-edit-profile-js": () => import("./../../../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-letter-js": () => import("./../../../src/pages/letter.js" /* webpackChunkName: "component---src-pages-letter-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-password-set-js": () => import("./../../../src/pages/password-set.js" /* webpackChunkName: "component---src-pages-password-set-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reqs-js": () => import("./../../../src/pages/reqs.js" /* webpackChunkName: "component---src-pages-reqs-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-reviewer-[uid]-js": () => import("./../../../src/pages/reviewer/[uid].js" /* webpackChunkName: "component---src-pages-reviewer-[uid]-js" */),
  "component---src-pages-reviewer-index-js": () => import("./../../../src/pages/reviewer/index.js" /* webpackChunkName: "component---src-pages-reviewer-index-js" */),
  "component---src-templates-task-js": () => import("./../../../src/templates/Task.js" /* webpackChunkName: "component---src-templates-task-js" */)
}

