import React from "react";
import Header from "gatsby-theme-carbon/src/components/Header";
import logo from "../../images/templogo.png";

const CustomHeader = (props) => (
  <Header {...props}>
    <img
      className="headerlogo"
      src={logo}
      alt="The Heed Ophthalmic Foundation"
      style={{
        marginRight: 15,
      }}
    />
  </Header>
);

export default CustomHeader;
