export default (userData, auth) => {
    let usr = null;
    for (const a_key of Object.keys(userData)) {
        if(userData[a_key] && userData[a_key].type === 'user--user'){
            usr = userData[a_key]
            break;
        }
    }
    if(usr){
        if(usr?.attributes?.field_is_eligible){
            usr.is_eligible = true; 
        }
        
        usr.token = auth.token;
    }else{
        usr = {}
    }
    return usr;
}
