import { UserContext, isLoggedIn } from "@parallelpublicworks/entitysync";
import React, { useContext, useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useQueryParam, StringParam } from 'use-query-params';
import { Loading } from "carbon-components-react";
import checkRoles from '../util/checkRoles'

const LoginWrap = ({ children, location }) => {
  const userContext = useContext(UserContext);
  const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null
  const userData = userState && userState.userData ? userState.userData : {}
  const [params] = useQueryParam('h', StringParam);
  const [isProfessor, setIsProfessor] = useState(null)
  const [isReviewer, setIsReviewer] = useState(null)
  const [settingRole, setSettingRole] = useState(false)

  useEffect(() => {
    if(!settingRole && isLoggedIn(userContext) && userData && Object.keys(userData).length > 0){
      setSettingRole(true);
      if(isProfessor === null){
        (async function(){
          const is_profe = await _checkRoles(userState.auth, userContext[1], userData)
          setIsProfessor(is_profe)
        })()
      }
      if(isReviewer === null){
        (async function(){
          const isReviewer = await _checkRoles(userState.auth, userContext[1], userData, 'reviewer')
          setIsReviewer(isReviewer)
        })()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext])

  async function _checkRoles(auth, dispatch, userData, roleType = 'professor'){
    for (let entity in userData) {
      if (userData[entity].type && userData[entity].type === 'user--user') {
        const is_profe = await checkRoles(auth, dispatch, userData[entity], roleType)
        // TODO: fix the password reset
        // loginReset(userData[entity]?.attributes?.mail, true)
        return is_profe
      }else{
        return null
      }
    }
    return false
  }


  // const loginReset = async (email, reset) => { 
  //   const base = siteMetadata.drupalUrl;
  //   const jsonapiEndpoint = 'professor/reset';
  //   const url = `${base}/${jsonapiEndpoint}`;
  //   if (typeof window !== `undefined`) {
  //     const resp = await axios.post(url, {email: email, reset: true})
  //     if (resp.status === 401 || resp.status === 403) {
  //       // error occurred
  //       // return false; 
  //     } else if (resp.status === 200) {
  //       // good response
  //     }        
  //   }
  // }

  if(typeof window === 'undefined'){
    return <></>
  }


  if (!isLoggedIn(userContext)) {
    if (params && params.length) {
      const decodedHash = atob(params)
      const [username, pass] = decodedHash.split(':')
      navigate("/login", {
        state: { referrer: location.pathname, username: username, password: pass },
      })
    } else {
      if (location.pathname.includes('/register')) {
        navigate("/register")
      } else {
        navigate("/login")
      }
    } 
    return <></>;
  }else{
    if(isProfessor === null || isReviewer === null){
      return <div id="global-loader"><Loading /></div>
    }else{
      if(isProfessor === true && (location.pathname.includes('application') || location.pathname.includes('edit-profile') || location.pathname === '/' || location.pathname.includes('review') || location.pathname.includes('reviewer') ) ){
        navigate('/letter')
        return <></>
      }else if(isReviewer === true && (location.pathname.includes('application') || location.pathname.includes('edit-profile') || location.pathname === '/' || location.pathname.includes('letter') ) ){
        navigate('/reviewer')
        return <></>
      } else if((isProfessor === false && isReviewer === false) && (location.pathname === '/letter' || location.pathname === '/letter/' || location.pathname === '/reviewer' || location.pathname === '/reviewer/') ){
        navigate('/application')
        return <></>
      }else{
        return children;
      }
    }
  }

};

export default LoginWrap;
