import React from 'react';
import { Row, Grid, Column } from 'gatsby-theme-carbon';
import {
  footer,
  grid,
  contact,
  logos,
  content
} from './Footer.module.scss';
import {Link} from 'gatsby'
import logo from '../../../images/templogo.png'
import { useStaticQuery, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser';

const Footer = () => {
  let nodfooter = useStaticQuery(graphql`
  {
    allNodeFooter {
      edges {
        node {
          body {
            value
          }
          drupal_id
          field_about_footer {
            value
          }
          field_connect_footer {
            value
          }
          id
        }
      }
    }
  }
`)
  nodfooter = nodfooter.allNodeFooter?.edges 
  ? nodfooter.allNodeFooter?.edges[0].node 
  : null   
  let aboutf = nodfooter.field_about_footer.value
  let connectf = nodfooter.field_connect_footer.value
  let contactf = nodfooter.body.value
  return (
    <footer className={footer}>
      <Grid className={grid}>
        <Row>
          <Column colLg={2} colMd={2} >
            <Link to="/" aria-label="Navigate to home page">
              <img 
              className={logos} 
              src={logo} 
              width="200" 
              alt="The Heed Ophthalmic Foundation" />
            </Link>
          </Column>
          <Column colLg={3} colMd={3} className={grid}>
            {ReactHtmlParser(aboutf)}
          </Column>
          <Column colLg={3} colMd={4} className={contact}>
            <div>{ReactHtmlParser(contactf)}</div>
          </Column>
          <Column colLg={3} colMd={3} className={grid}>
            <div>{ReactHtmlParser(connectf)}</div>
          </Column>
        </Row>
      </Grid>
    </footer>
  );
};

export default Footer;
